import clsx from "clsx"
import React, { useState } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import Button from "../components/fundamentals/button"
import SignInInput from "../components/molecules/input-signin"
import SEO from "../components/seo"
import LoginLayout from "../components/templates/login-layout"
import toast, { Toaster } from "react-hot-toast"
import AuthController from '../services/auth'

type FormValues = {
  email: string
  password: string
  shop_name: string
  confirm_password: string
}

const RegisterForm = () => {
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm<FormValues>({
    mode: 'onChange'
  })
  const [isRegisterLoading, setRegisterLoading] = useState<boolean>(false)


  const confirmPasswordWatch = watch('password')

  const onSubmit = async (values: FormValues) => {

    setRegisterLoading(true)
    const response = await AuthController.register(values).finally(() => {
      setTimeout(() => {
        setRegisterLoading(false);
      }, 1000);
    });

    if (response?.data?.status !== 200 && response?.data?.meta?.code !== 201) {
      toast.error(response?.data?.message)
    }

    toast.success("Register Succeed",)
    return navigate("/login")
  }

  return (
    <LoginLayout>
      <SEO title="Register" />
      <Toaster position="top-right" />
      <div className="flex h-full w-full items-center justify-center">
        <div
          className={clsx(
            "flex min-h-[600px] w-[640px] bg-grey-0 rounded-rounded justify-center transition-['min-height'] duration-300"
          )}
        >
          <div className="flex flex-col justify-center w-full px-[120px] items-center">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col items-center">
                <span className="inter-2xlarge-semibold mt-4 mb-4 text-grey-90">
                  Register
                </span>

                <SignInInput
                  placeholder="email..."
                  {...register("email", {
                    required: true,
                    validate: e => {
                      if (!e || e === '')
                        return 'Do not empty';

                      const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      if (!emailRegex.test(e))
                        return 'Invalid email';

                      return;
                    }
                  })}
                  autoComplete="email"
                />
                {
                  errors['email'] && errors['email'].message && <small className="text-red-400">
                    {errors['email'].message}
                  </small>
                }
                <SignInInput
                  placeholder="Password..."
                  type={"password"}
                  {...register("password", {
                    required: true,
                    validate: e => {
                      if (e.length < 8)
                        return 'Password must be at least 8 characters';

                      return;
                    }
                  })}
                  autoComplete="current-password"
                />
                {
                  errors['password'] && errors['password'].message && <small className="text-red-400">
                    {errors['password'].message}
                  </small>
                }
                <SignInInput
                  placeholder="Confirm password..."
                  type={"password"}
                  {...register("confirm_password", {
                    required: true,
                    validate: e => {
                      if (e !== confirmPasswordWatch)
                        return 'Confirm password is not correct';

                      return;
                    }
                  })}
                />
                {
                  errors['confirm_password'] && errors['confirm_password'].message && <small className="text-red-400">
                    {errors['confirm_password'].message}
                  </small>
                }
                <Button
                  className="rounded-rounded mt-4 w-[320px] inter-base-regular"
                  variant="primary"
                  size="large"
                  type="submit"
                  loading={isRegisterLoading}
                >
                  Continue
                </Button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </LoginLayout>
  )
}

export default RegisterForm
